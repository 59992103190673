<template>
  <div class="page-main flex-page">
    <!--START: Header Wrapper-->
    <div
      class="header-wrapper"
      :class="{ 'show-shadow': showOptions.headerShadow }"
    >
      <!--START: Page Header-->
      <RegularHeader
        class="regular-header"
        :data="regularHeader"
      ></RegularHeader>
      <!--END: Page Header-->
      <button class="btn btn-small btn-grey" @click="addSource">
        Add Source
      </button>
    </div>
    <!--END: Header Wrapper-->

    <!--START: Zero State -->
    <ZeroState
      v-if="company.sources.length == 0"
      class="zero-state"
      :imageURL="zeroStateData.imageURL"
      :title="zeroStateData.title"
      :message="zeroStateData.message"
      :buttonTitle="zeroStateData.buttonTitle"
      @eventHandler="addSource"
    ></ZeroState>
    <!--END: Zero State -->

    <!--START: Sources Table-->
    <SourcesTable
      v-else
      :sources="company.sources"
      @sourceSelected="showSourceDocuments"
      @deleteSource="deleteSource"
      @resyncSource="resyncSource"
    ></SourcesTable>
    <!--END: Sources Table-->

    <!--START: Loader-->
    <PageLoader v-if="!hasCompanyLoaded" :showLoader="true"></PageLoader>
    <!--END: Loader-->

    <!--START: Add Source-->
    <AddSource
      v-if="showOptions.addSource"
      @closeModal="closeModal"
    ></AddSource>
    <!--END: Add Source-->

    <!--START: Notification Message-->
    <NotificationMessage
      :show="status.show"
      :status="status.status"
      :title="status.title"
    ></NotificationMessage>
    <!--END: Notification Message-->

    <!--START: Loader-->
    <LineLoader :show="showOptions.lineLoader"></LineLoader>
    <!--END: Loader-->
  </div>
</template>
        
<script>
// Import libraries
import _ from "lodash";

// Importing components
import RegularHeader from "@/components/dashboard/RegularHeader";
import AddSource from "@/components/dashboard/knowledgeBase/AddSource";
import SourcesTable from "@/components/dashboard/tables/SourcesTable";
import ZeroState from "@/components/misc/ZeroState";
import NotificationMessage from "@/components/modals/NotificationMessage";
import LineLoader from "@/components/loaders/LineLoader";
import PageLoader from "@/components/loaders/PageLoader";

// Importing Services
import { DocumentService } from "@/services";

export default {
  name: "KnowledgeBaseSources",
  data() {
    return {
      showOptions: {
        addSource: false,
        lineLoader: false,
        initDocuments: false,
        zeroState: false,
        viewDocument: false,
      },
      regularHeader: {
        title: "Sources",
        description: "Manage all the sources used for your knowledge base",
      },
      zeroStateData: {
        imageURL: "services.jpg",
        title: "Add Sources to Knowledege Base",
        message:
          "Add sources to your knowledge base for the AI to use while generating answers",
        buttonTitle: "Add Source",
      },
      status: {
        show: false,
        status: "success",
        title: "Source deleted",
      },
      sources: [],
    };
  },
  props: {},
  watch: {},
  components: {
    RegularHeader,
    AddSource,
    SourcesTable,
    ZeroState,
    NotificationMessage,
    LineLoader,
    PageLoader,
  },
  computed: {
    company() {
      return this.$store.getters.company;
    },

    hasCompanyLoaded() {
      return !_.isEmpty(this.company);
    },
  },
  async created() {},
  methods: {
    addSource() {
      this.showOptions.addSource = true;
    },

    showSourceDocuments(sourceID) {
      this.$router.push({ path: `/knowledge-base/sources/${sourceID}` });
    },

    async deleteSource(sourceID) {
      this.showOptions.lineLoader = true;

      const data = { sourceID };
      await DocumentService.DeleteSource(data);

      // Remove from the table
      const index = this.company.sources.findIndex((s) => s._id == sourceID);
      this.company.sources.splice(index, 1);
      this.$store.commit("updateCompany", this.company);

      this.status.title = "Source deleted";
      this.showStatusMessage(this.status, 2500);
      this.showOptions.lineLoader = false;

      this.$router.push({ path: "/knowledge-base/sources" });
    },

    async resyncSource(source) {
      this.showOptions.lineLoader = true;
      let data = { sourceID: source._id };
      await DocumentService.DeleteSource(data);

      data = source;
      const response = await DocumentService.AddSource(data);
      if (!response.hasError) {
        this.showOptions.state = "success";
        this.$store.commit("updateCompany", response.data);
      } else this.showErrorMessage(this.status, response.message);

      this.showOptions.lineLoader = false;
    },

    closeModal() {
      this.showOptions.addSource = false;
    },
  },
};
</script>
        
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
</style>